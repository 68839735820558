import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import qux from './qux'
import Services from './services/Services'

await Services.initConfig()
const app = createApp(App)

app.use(router).mount('#app')
app.use(qux)
app.config.unwrapInjectedRef = true 