import UserService from './UserService'
import ModelService from './ModelService'


class Services {

    constructor () {
        this.config = {
            'default': true,
            'auth': 'qux',
            'api': 'https://v1.quant-ux.com/',
            'user': {
                'allowSignUp': true
            },
            'websocket': '',
            'proxy_URL': 'http://localhost:8084',
            'app_URL': 'http://localhost:8081',
            'api_URL': 'https://api-qa.flowrabbit.ai',
            'node_URL': 'https://node-qa.flowrabbit.ai'
        }
    }

    async initConfig () {
        return new Promise((resolve, reject) => {
            fetch('/config.json', {
                method: 'get',
                credentials: "same-origin"
            }).then((res) => {
                if (res.status === 200) {
                    res.json().then(j => {                        
                        console.debug("Luisa > config: ", j)
                        this.config = j
                        resolve(j)
                    })
                } else {
                    reject(new Error('Could not load config'))
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getConfig() {
        return this.config
    }

    setErrorHandler (handler) {
        this.errorHandler = handler
        ModelService.setErrorHandler(handler)
        UserService.setErrorHandler(handler)
    }

    getUserService () {
     
        UserService.setToken(UserService.getToken())
        return UserService   
        
    }

    getModelService () {
        ModelService.setToken(this.getUserService().getToken())
        return ModelService
    }

}
export default new Services()