import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Embedded from '../views/embedded/Embedded.vue'

function isCustomDomain() {
  const hostname = window.location.origin;
  const name = removeWWWAndProtocol(hostname)
  return !name.includes('flowrabbit.ai') && !name.includes('localhost')
}

function removeWWWAndProtocol(origin) {
  const url = new URL(origin);
  const hostnameParts = url.hostname.split('.');
  if (hostnameParts[0] === 'www') {
      hostnameParts.shift();
  }
  const hostnameWithoutWWW = hostnameParts.join('.');
  return hostnameWithoutWWW;
}

function getDomainName() {
  const hostname = window.location.origin;
  const name = removeWWWAndProtocol(hostname)
  return name
}

function getRoutes (){

  if (isCustomDomain()) {
    const domain = getDomainName()
    console.debug('router.getRoutes() > Custom domain', domain)
    return [
      {
        path: '/:appName.html',
        name: 'CustomHome',
        component: Home,
        meta: {isCustomDomain:true, domain:domain}
      },
      {
        path: '/:appName/:screenName.html',
        name: 'CustomHome2',
        component: Home,
        meta: {isCustomDomain:true, domain:domain}
      }
    ]
  } else {
    return [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/:orgName/:appName.html',
        name: 'Home2',
        component: Home
      },
      {
        path: '/:orgName/:appName/:screenName.html',
        name: 'Home3',
        component: Home,
        meta: {isEmbedded:true}
      },
      {
        path: '/embedded/:id.html',
        name: 'Embedded',
        component: Embedded,
        meta: {isEmbedded:true}
      },
      {
        path: '/embedded/:id/:screenName.html',
        name: 'Embedded2',
        component: Embedded
      }
    ]
  }
}


const routes = getRoutes()
const isHashRouter = false
const router = createRouter({
  history: isHashRouter ? createWebHashHistory () : createWebHistory(),
  routes
})

export default router
