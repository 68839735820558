import Logger from './Logger'
import Services from "../../services/Services";
import RestUtil from "../../util/RestUtil.js";
import ConstantsUtil from "../../util/ConstantsUtil.js";

class RestEngine {
  constructor() {
    this.proxyURL = `${Services.getConfig().proxy_URL}/proxy`;
  }

  run(request, data, hash, appID) {
    Logger.log(-1, "RestEngine.run()", hash, appID);

    if (request.method === "GET") {
      return this.get(request, data, hash, appID);
    }

    if (request.method === "POST" && request.input.type === "FORM") {
      return this.postOrPutForm(request, data, hash, appID);
    }
    if (request.method === "POST" && request.input.type === "JSON") {
      return this.postOrPut(request, data, hash, appID);
    }
    if (
      request.method === "POST" &&
      (request.input.type === "IMAGE" || request.input.type === "FILE")
    ) {
      return this.postOrPostImage(request, data, hash, appID);
    }

    if (request.method === "PATCH" && request.input.type === "JSON") {
      return this.postOrPut(request, data, hash, appID);
    }
    if (request.method === "PATCH" && request.input.type === "FORM") {
      return this.postOrPutForm(request, data, hash, appID);
    }
    if (
      request.method === "PATCH" &&
      (request.input.type === "IMAGE" || request.input.type === "FILE")
    ) {
      return this.postOrPostImage(request, data, hash, appID);
    }

    if (request.method === "PUT" && request.input.type === "JSON") {
      return this.postOrPut(request, data, hash, appID);
    }
    if (request.method === "PUT" && request.input.type === "FORM") {
      return this.postOrPutForm(request, data, hash, appID);
    }
    if (
      request.method === "PUT" &&
      (request.input.type === "IMAGE" || request.input.type === "FILE")
    ) {
      return this.postOrPostImage(request, data, hash, appID);
    }

    if (request.method === "DELETE") {
      return this.delete(request, data, hash, appID);
    }
  }

  async runAPILoop(prevResult, request, values, hash, appID, type) {
    try {
      if (type === "replicate") {
        Logger.log(1, "runAPILoop: Get replicate by Job Id: ");
        request.url = prevResult.urls.get;
        let result = {};
        while (!result.status || result.status !== "succeeded") {
          result = await this.get(request, values, hash, appID);
          if (!result.status || result.status !== "succeeded") {
            await RestUtil.delay(1000);
          }
        }
        let path = request.output.path || "";
        return RestUtil.getValueByPath(result, path);
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
      throw new Error("Something was wrong. Run did not complete");
    }
  }

  async buildFormData(request, values) {
    const formData = new FormData();
    const lines = request.input.template.split("\n");
    for (let line of lines) {
      const parts = line.split(":");
      if (parts.length === 2) {
        const key = parts[0];
        const value = parts[1];
        const data = await RestUtil.fillString(value, values, true);
        formData.append(key, data);
      } else {
        throw new Error("RestEngine.buildFormData() > template not ok");
      }
    }
    Logger.log(-1, "RestEngine.buildFormData()", "exit", formData);
    return formData;
  }

  handleOutput(resolve, reject, request, response) {
    Logger.log(2, "RestEngine.handleOutput()", "enter", response);

    if (response.status == 200 || response.status == 201) {
      if (request.output.type === "JSON") {
        try {
          resolve(response.json());
        } catch (e) {
          reject(
            new Error(
              `Could not ${request.method} ${request.url}: ${e.message}`
            )
          );
        }
      }
      if (request.output.type === "TEXT") {
        resolve(response.text());
      }
      if (request.output.type === "IMAGE") {
        response.arrayBuffer().then((buffer) => {
          resolve(buffer);
        });
      }
      if (request.output.type === "FILE") {
        response
          .blob()
          .then((blob) => {
            const fileUrl = URL.createObjectURL(blob);
            resolve(fileUrl);
          })
          .catch((e) => {
            reject(
              new Error(
                `Could not ${request.method} ${request.url}: ${e.message}`
              )
            );
          });
      }
      return;
    }
    reject(
      Error(
        `Could not ${request.method} ${request.url}: ${response.statusText}`
      )
    );
  }

  get(request, values, hash, appID) {
    return new Promise(async (resolve, reject) => {
      let url = await RestUtil.buildURL(request, values);
      let header = await RestUtil.createDefaultHeader(request, values);

      url = this.makeProxyRequestIfNeeded(
        request,
        url,
        header,
        hash,
        appID
      );

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
      })
        .then((response) => {
          this.handleOutput(resolve, reject, request, response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  postOrPostImage(request, values, hash, appID) {
    return new Promise(async (resolve, reject) => {
      let url = await RestUtil.buildURL(request, values);
      const header = await RestUtil.createDefaultHeader(request, values);
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }

      url = this.makeProxyRequestIfNeeded(
        request,
        url,
        header,
        hash,
        appID
      );

      fetch(url, {
        method: request.method,
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
        body: formData,
      })
        .then((response) => {
          this.handleOutput(resolve, reject, request, response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  postOrPutForm(request, values, hash, appID) {
    Logger.log(1, "RestEngine.postOrPutForm()", "enter >");
    return new Promise(async (resolve, reject) => {
      let url = await RestUtil.buildURL(request, values);
      const formData = await this.buildFormData(request, values);
      const header = await RestUtil.createDefaultHeader(request, values);

      url = this.makeProxyRequestIfNeeded(
        request,
        url,
        header,
        hash,
        appID
      );

      fetch(url, {
        method: request.method,
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
        body: formData,
      })
        .then((response) => {
          this.handleOutput(resolve, reject, request, response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  postOrPut(request, values, hash, appID) {
    return new Promise(async (resolve, reject) => {
      let url = await RestUtil.buildURL(request, values);
      let data = await RestUtil.buildData(request, values);
      let header = await RestUtil.createDefaultHeader(request, values);

      url = this.makeProxyRequestIfNeeded(
        request,
        url,
        header,
        hash,
        appID
      );

      fetch(url, {
        method: request.method,
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
        body: data,
      })
        .then((response) => {
          this.handleOutput(resolve, reject, request, response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  delete(request, values, hash, appID) {
    return new Promise(async (resolve, reject) => {
      let url = await RestUtil.buildURL(request, values);
      let header = await RestUtil.createDefaultHeader(request, values);

      url = this.makeProxyRequestIfNeeded(
        request,
        url,
        header,
        hash,
        appID
      );

      fetch(url, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
      })
        .then((response) => {
          this.handleOutput(resolve, reject, request, response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getNeededDataBings(rest) {
    let result = [];
    RestUtil.parseString(rest.url, result);
    RestUtil.parseString(rest.token, result, true);
    if (
      (rest.method === "POST" ||
        rest.method === "PUT" ||
        rest.method === "PATCH") &&
      (rest.input.type === "JSON" || rest.input.type === "FORM")
    ) {
      RestUtil.parseString(rest.input.template, result);
    }
    if (rest.input.fileDataBinding) {
      result.push(rest.input.fileDataBinding);
    }
    if (rest.headers) {
      rest.headers.forEach((header) => {
        RestUtil.parseString(header.key, result, true);
        RestUtil.parseString(header.value, result, true);
      });
    }

    return result;
  }

  async secondApiCall(request, params, values, hash, appID) {
    let url = request.url + params;
    const condition = request.condition;
    let conditionValue = undefined;
    let header = await RestUtil.createDefaultHeader(request, values);
    url = this.makeProxyRequestIfNeeded(request, url, header, hash, appID);
    while (conditionValue !== condition.value) {
      const response = await fetch(url, {
        method: request.method,
        mode: "cors",
        cache: "no-cache",
        headers: header,
        redirect: "follow",
        referrer: "no-referrer",
      });
      if (response.status == 200 || response.status == 201) {
        if (request.output.type === "JSON") {
          try {
            const result = response.json();
            conditionValue = result[condition.key];
          } catch (e) {
            new Error(
              `Could not ${request.method} ${request.url}: ${e.message}`
            );
          }
        }
        if (request.output.type === "IMAGE") {
          response.arrayBuffer().then((buffer) => {
            const result = buffer;
            conditionValue = result[condition.key];
          });
        }
        return;
      }
    }
  }

  makeProxyRequestIfNeeded(request, url, headers, hash, appID) {
    if (RestUtil.isProxyRequest(request)) {
      Logger.log(
        -1,
        "RestUtil.makeProxyRequestIfNeeded() > make proxy",
        hash,
        appID
      );
      if (headers) {
        let headerKeys = Object.keys(headers).join(";");
        headers[ConstantsUtil.flowrabbitHeaders.HEADER_FLOWRABBIT] = headerKeys;
      }
      headers[ConstantsUtil.flowrabbitHeaders.HEADER_FLOWRABBIT_HOST] = url;
      headers[ConstantsUtil.flowrabbitHeaders.HEADER_FLOWRABBIT_HASH] = hash;
      headers[ConstantsUtil.flowrabbitHeaders.HEADER_FLOWRABBIT_APP_ID] = appID;

      return `${Services.getConfig().proxy_URL}/proxy`;
    }
    return url;
  }
}
export default new RestEngine();
