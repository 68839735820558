const image = {
  id: "image",
  title: "Image AI",
  icon: "mdi mdi-image",
  logo: "ai-image",
  brands: [
    { id: "replicate", label: "Replicate", logo: "ai-replicate" },
    { id: "openai", label: "OpenAI", logo: "ai-openai" },
  ],
  models: [
    {
      id: "openai-dalle-2",
      name: "OpenAI - DALL·E 2",
      url: "https://api.openai.com/v1/images/generations",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "openai",
      elements: [
        {
          options: [
            "256x256",
            "512x512",
            "1024x1024",
            "1024x1792",
            "1792x1024",
          ],
          default: "1024x1024",
          type: "DropDown",
          required: true,
          id: "size",
          label: "Size",
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://platform.openai.com/docs/guides/images/usage?lang=curl",
      getTemplate: (vars) => {
        return {
          model: "dall-e-2",
          ...vars,
          n: 1,
          response_format: "url",
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "openai-dalle-3",
      name: "OpenAI - DALL·E 3",
      url: "https://api.openai.com/v1/images/generations",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "openai",
      elements: [
        {
          options: ["1024x1024", "1024x1792", "1792x1024"],
          default: "1024x1024",
          type: "DropDown",
          required: true,
          id: "size",
          label: "Size",
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          options: ["standard", "hd"],
          default: "standard",
          type: "DropDown",
          required: true,
          id: "quality",
          label: "Quality",
          helper: "Number of images to generate.",
        },
        {
          options: ["natural", "vivid"],
          default: "natural",
          type: "DropDown",
          required: true,
          id: "style",
          label: "Style",
          helper: "Number of images to generate.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://platform.openai.com/docs/guides/images/usage?lang=curl",
      getTemplate: (vars) => {
        return {
          model: "dall-e-3",
          ...vars,
          n: 1,
          response_format: "url",
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "replicate-stbl-diffusion",
      name: "Replicate - Stable Diffusion",
      url: "https://api.replicate.com/v1/predictions",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "replicate",
      elements: [
        {
          type: "flex",
          content: [
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "width",
              label: "Width",
            },
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "height",
              label: "Height",
            },
          ],
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          options: [
            "K_EULER",
            "DDIM",
            "DPMSolverMultistep",
            "K_EULER_ANCESTRAL",
            "PNDM",
            "KLMS",
          ],
          default: "K_EULER",
          type: "DropDown",
          required: true,
          id: "scheduler",
          label: "Scheduler",
        },
        {
          type: "flex",
          content: [
            {
              options: [1, 2, 3, 4],
              default: 1,
              type: "DropDown",
              min: 1,
              max: 4,
              required: true,
              id: "num_outputs",
              label: "Num Outputs",
              helper: "Number of images to generate.",
            },
            {
              type: "Number",
              id: "seed",
              label: "Seed",
            },
          ],
        },
        {
          default: 7.5,
          min: 1,
          max: 20,
          type: "range",
          required: true,
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true
        },
        {
          default: 50,
          min: 1,
          max: 500,
          type: "range",
          required: true,
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          type: "InputArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper: "Specify things to not see in the output",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "ac732df83cea7fff18b8472768c88ad041fa750ff7682a21affe81863cbe77e4",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "stability-ai-sdxl",
      name: "Replicate - Stability Sdxl",
      url: "https://api.replicate.com/v1/predictions",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "replicate",
      elements: [
        {
          type: "flex",
          content: [
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "width",
              label: "Width",
            },
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "height",
              label: "Height",
            },
          ],
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          options: [
            "K_EULER",
            "DDIM",
            "DPMSolverMultistep",
            "K_EULER_ANCESTRAL",
            "PNDM",
            "KLMS",
          ],
          default: "K_EULER",
          type: "DropDown",
          required: true,
          id: "scheduler",
          label: "Scheduler",
        },
        {
          type: "flex",
          content: [
            {
              options: [1, 2, 3, 4],
              default: 1,
              type: "DropDown",
              min: 1,
              max: 4,
              required: true,
              id: "num_outputs",
              label: "Num Outputs",
              helper: "Number of images to generate.",
            },
            {
              type: "Number",
              id: "seed",
              label: "Seed",
            },
          ],
        },
        {
          default: 7.5,
          min: 1,
          max: 20,
          type: "range",
          required: true,
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true
        },
        {
          default: 50,
          min: 1,
          max: 500,
          type: "range",
          required: true,
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          required: true,
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
            decimals: true
        },
        {
          options: [
            "no_refiner",
            "expert_ensemble_refiner",
            "base_image_refiner",
          ],
          default: "no_refiner",
          type: "DropDown",
          id: "refine",
          label: "Refine",
          helper: "Which refine style to use",
        },
        {
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications. If you have other provisions for generating or deploying images safely, you can use this to disable watermarking.",
        },
        {
          type: "InputArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper: "Specify things to not see in the output",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "39ed52f2a78e934b3ba6e2a89f5b1c712de7dfea535525255b1aa35c5565e08b",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
  ],
};

export default image;
