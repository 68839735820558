const flowrabbitHeaders = {
    HEADER_FLOWRABBIT: 'x-flowrabbit-headers',
    HEADER_FLOWRABBIT_MODEL: "x-flowrabbit-secret-name",
    HEADER_FLOWRABBIT_HOST: 'x-forwarded-host',
    HEADER_FLOWRABBIT_HASH: 'x-flowrabbit-hash',
    HEADER_FLOWRABBIT_APP_ID: "x-flowrabbit-appid",
    HEADER_FLOWRABBIT_STREAM_PATH: "x-flowrabbit-stream-path"

}

const ConstantsUtil = {
    flowrabbitHeaders
}

export default ConstantsUtil;