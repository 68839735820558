const textToSpeech = {
  id: "textToSpeech",
  title: "Text to Speech",
  icon: "mdi mdi-music",
  logo: "ai-text-speech",
  brands: [
    { id: "replicate", label: "Replicate", logo: "ai-replicate" },
    { id: "deepgram", label: "Deepgram", logo: "ai-deepgram" },
    { id: "elevenlabs", label: "ElevenLabs", logo: "ai-elevenlabs" },
  ],
  models: [
    {
      id: "replicate-suno-bark",
      name: "Suno Bark",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          options: [
            "de_speaker_0",
            "en_speaker_0",
            "es_speaker_0",
            "fr_speaker_0",
          ],
          default: "announcer",
          type: "DropDown",
          label: "History Prompt",
          id: "history_prompt",
          helper: "History choice for audio cloning, choose from the list",
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          default: 0.7,
          min: 0,
          max: 1,
          type: "range",
          required: true,
          id: "text_temp",
          label: "Text Temperature",
          helper:
            "Generation temperature (1.0 more diverse, 0.0 more conservative)",
        },
        {
          default: 0.7,
          min: 0,
          max: 1,
          type: "range",
          required: true,
          id: "waveform_temp",
          label: "Waveform Temperature",
          helper:
            "Generation temperature (1.0 more diverse, 0.0 more conservative)",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "b76242b40d67c76ab6742e987628a2a9ac019e11d56ab96c4e91ce03b79b2787",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output.audio_out",
        },
        authType: "Bearer",
      },
    },
    {
      id: "elevenlabs-speech",
      name: "V1",
      url: "https://api.elevenlabs.io/v1/text-to-speech/",
      type: "elevenlabs",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          type: "custom",
          id: "voice_selection"
        },
      ],
      method: "POST",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          text: vars.prompt,
          model_id: "eleven_monolingual_v1",
        };
      },
      output: {
        path: "",
        type: "FILE",
      },
      authHeader: "xi-api-key",
    },
  ],
};
export default textToSpeech;
